.DateStories {
  &-limitReached {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    align-items: center;
    margin: auto;
  }
  &-tab {
    color: #E6E6E6 !important;
  }
  &-tabs {
    .MuiTabs-scroller{
      .MuiTabs-indicator{
        background-color: #FF7BAC !important;
      }
    }
  }
}