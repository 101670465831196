// menu item style overriding
.MuiPopover-root .MuiPopover-paper {
  margin-top: 0.25rem;
  box-shadow: var(--primary-box-shadow);
  border-radius: 1.125rem;
  .MuiList-root {
    padding: 1rem;
    .MuiMenuItem-root {
      height: 3rem;
      padding: 0 0.5rem;
      justify-content: center;
      font-size: 0.75rem;
      border-radius: 0.5625rem;
      &.Mui-selected {
        background-color: var(--burgundyTint9);
        color: var(--burgundy);
      }
      color: #FF7BAC !important;
      font-size: 17px;
    }
  }
}

.input-field--without-label {
  input {
    padding: 20px !important;
  }
}
