.date-review-option {
    text-align: center;
    border: 0.063rem solid #5E5C5C;
    border-radius: 1rem;
    width: 7.5rem;
    padding: 1.25rem;
    margin: 0 0.5rem;
    cursor: pointer;
    &.selected {
        border-color: #ffffff;
    }
    img {
        width: 3rem;
        max-width: 100%;
        margin: 0 auto;
    }
}