:root {
  // PRIMARY COLORS AND SHADES

  // SHADES OF BURGUNDY
  --burgundy: #8a121e;
  --lightBurgundy: #b97178;
  --burgundyTint5: #c5898f;
  --burgundyTint9: #f3e7e9;

  // SHADES OF RED CARNATION
  --redCarnation: #fa0202;
  --redCarnationTint9: #ffe6e6;

  // SHADES OF BLACK
  --black: #000000;
  --blackTint1: #1a1a1a;
  --blackTint4: #666666;

  // SHADES OF GREY
  --darkGrey: #5e5c5c;
  --greyTint7: #cfcece;
  --greyTint9: #efefef;
}
