html, body {
  scroll-behavior: smooth;
  height: 100%;
  background-color: #000;
}

#root {
  height: 100%;
}

* {
  font-family: "MyriadProLightSemiExtended", "Mulish", "Helvetica", "Arial", sans-serif;
  -webkit-font-smoothing: subpixel-antialiased !important;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

//--Pink Theme changes, we need it globaly
input[type=text] {
  background-clip: text;
  color: #E6E6E6;
}

input:-webkit-autofill {
  -webkit-box-shadow:none;
  -webkit-text-fill-color: #E6E6E6;
}
input:-webkit-autofill:focus {
  -webkit-box-shadow: none;
  -webkit-text-fill-color: #E6E6E6;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  box-shadow: inset 0 0 5px #E6E6E6;
  border-radius: 4px;
  border-left: 1.5px solid transparent;
  border-right: 1.5px solid transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #FF7BAC;
  border-radius: 4px;
}
//----------||----------