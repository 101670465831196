$root: ".LocationSearchInput";

#{$root} {
  .MuiInputBase-root {
    width: 22.5rem !important;
  }
  .autocomplete-dropdown-container {
    background: #000;
    position: absolute;
    z-index: 2;
  }
}
