$root: ".Map";

#{$root} {
  .gm-style-iw {
    width: 340px;
    background-color: #000;
    // box-shadow: 0px 0px 10px rgba(207, 206, 206, 0.8);
    border-radius: 18px;
    padding: 0;
    .gm-style-iw-d {
      overflow: hidden !important;
      padding: 0;
    }
    .gm-ui-hover-effect {
      top: 4px !important;
      right: 4px !important;
    }
  }
  .gm-style-iw-tc {
    display: none;
  }
  .gm-ui-hover-effect {
    visibility: hidden;
  }
}
