.spotify-search-box {
  padding: 0 1rem 1rem;
  max-height: 300px;
  border-radius: 18px !important;
  width: calc(100% - 32px);
  top: 100%;
  z-index: 1;
}

.audio-icon {
    position: absolute !important;
    background: none;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}

.input-field-container {
    background: #000000;
    padding: 0.6rem 0;
    position: sticky;
    top: 0;
    z-index: 1;
}

.song-image {
    width: 100%;
    border-radius: 0.25rem;
}

.song-audio {
    position: absolute;
    top: 8px;
    left: 0;
    filter: sepia(20%) saturate(70%) grayscale(1) contrast(99%) invert(12%);
    width: 101px;
    height: 28px;
    visibility: hidden;
}
