$root: ".Welcome";

#{$root} {
  background-image: url("datehouse-background.png");
  background-repeat: no-repeat;
  background-size: 100vw 100%;
  padding: 5.5rem 0 3.75rem 0;
}

@media only screen and (max-width: 899px) {
  #{$root} {
    background: #000;
    .artist-container {
      display: none;
    }
  }
}
