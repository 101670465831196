$root: ".WelcomeCard";

#{$root} {
  &-logo {
    margin-bottom: 2rem;
  }
}


@media only screen and (max-width: 899px) {
  #{$root} {
    &-logo {
      width: 290px;
    }
  }
}