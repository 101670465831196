.react-tags-wrapper {
    width: 100%;
    border-radius: 0.75rem;
    background-color: #858585;
    padding: 1.25rem;
    border: 2px solid transparent;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
    box-sizing: border-box;
    &.focused {
        border: 2px solid #C5898F;
    }

    .ReactTags__selected {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        position: relative;
    }

    .ReactTags__tagInput {
        background-color: transparent;
        border: none;
        font-size: 18px;
        height: 100%;
        width: 100%;

        &:focus {
            outline: none;
        }
    }
    input {
        font-size: 17px;
    }
    ::placeholder {
        color: #E6E6E6;
        opacity: 1; /* Firefox */
      }
}

.addButtonBox {
    .MuiButtonBase-root {
        .MuiTypography-root{
            color: #E6E6E6;
        }
    }
}  
.tag-wrapper {
    background-color: #FF7BAC;
    &.suggestion {
        background-color: #000;
        color: #E6E6E6;
        cursor: pointer;
        padding: 0 10px 0 0;
    }
    border-radius: 0.5rem;
    padding: 0.375rem 0.75rem;
    color: white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    margin: 0.125rem;
    font-size: 18px;

    .ReactTags__remove {
        padding: 0;
        margin-left: .5rem;
        background-color: transparent;
        border: none;
        color: white;
        cursor: pointer;
    }
}