$root: ".AdminDashboard";

#{$root} {
  &-DateRangeSelect {
    & .MuiSelect-select {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
}

.AverageExperienceTrend {
  display: flex;
  flex-direction: row;
  align-items: center;
  &_value {
    padding: 16px;
    color: #fff;
    border-radius: 8px;
    width: 64px;
    text-align: center;
  }
  &_label {
    margin-left: 12px;
  }
}
.apexcharts-tooltip {
  white-space: normal !important;
}
.user_trend_tooltip {
  padding: 4px 8px;
  font-weight: 700;
  font-size: 10px;
}
.location_trend_tooltip {
  padding: 4px 8px;
  max-width: 400px;
  .address {
    font-size: 10px;
    color: #666666;
    margin-top: 4px;
  }
  .rating {
    font-size: 10px;
    font-weight: 700;
    margin-top: 4px;
    &_best {
      color: #218380;
    }
    &_worst {
      color: #fa0202;
    }
  }
}
