.flex-column {
    flex-direction: column;
  }
  
  .justify-content-start {
    justify-content: flex-start;
  }
  
  .justify-content-center {
    justify-content: center;
  }
  
  .justify-content-between {
    justify-content: space-between;
  }
  
  .justify-content-end {
    justify-content: flex-end;
  }
  
  .align-items-center {
    align-items: center;
  }

  .align-items-end {
    align-items: flex-end;
  }
  
  .flex-wrap {
    flex-wrap: wrap;
  }
  
  .w-100 {
    width: 100%;
  }
  
  .h-100 {
    height: 100%;
  }
  
  .disabled {
    pointer-events: none;
    opacity: 0.4;
  
    &:hover {
      box-shadow: none;
    }
  }
  
  .read-only {
    pointer-events: none;
  
    &:hover {
      box-shadow: none;
    }
  }
  
  .text-uppercase {
    text-transform: uppercase;
  }

  .text-align-end{
    text-align: end;
  }

  .text-align-start{
    text-align: start;
  }

  .text-align-center{
    text-align: center;
  }
  
  .cursor-pointer {
    cursor: pointer;
  }
  
  .d-none {
    display: none !important;
  }
  
  .d-inline {
    display: inline !important;
  }
  
  .d-inline-block {
    display: inline-block !important;
  }
  
  .d-block {
    display: block !important;
  }
  
  .d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  
  .d-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  
  // Mobile devices
  @media (min-width: 576px) {
    .d-sm-none {
      display: none !important;
    }
  
    .d-sm-inline {
      display: inline !important;
    }
  
    .d-sm-inline-block {
      display: inline-block !important;
    }
  
    .d-sm-block {
      display: block !important;
    }
  
    .d-sm-flex {
      display: -ms-flexbox !important;
      display: flex !important;
    }
  
    .d-sm-inline-flex {
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }

    .w-sm-100 {
      width: 100%;
    }
  }
  
  // Tablet devices
  @media (min-width: 768px) {
    .d-md-none {
      display: none !important;
    }
    .d-md-inline {
      display: inline !important;
    }
    .d-md-inline-block {
      display: inline-block !important;
    }
    .d-md-block {
      display: block !important;
    }
    .d-md-flex {
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .d-md-inline-flex {
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }

    .w-md-60 {
      width: 60%;
    }
  }

  .text-align-start{
    text-align: start;
  }
  .black-background{
   background-color: #000 !important;
  }
  .grey-border1{
    border: 1px solid #E6E6E6 !important;
   }