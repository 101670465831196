.map-loader-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 304px;
    .linear-progress {
        span {
            border-radius: 64px;
        }
    }
}