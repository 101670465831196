$root: ".Card";

#{$root} {
  &.MuiCard-root {
    background: #000;
    // border: 1px solid #b3b3b3;
    // box-shadow: 0px 0px 22px #cfcece;
    // border-radius: 52px;
  }
}
