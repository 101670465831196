.ratingBox{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    .ratingButton{
        flex: 48%;
        display: flex;
        justify-content: start;
        text-transform: none;
        font-size: 20px;
        &:hover {
            background: none;
        }
        &.selected {
            // border: 2px solid #FF7BAC;
            background: #ff7bac73;
        }
        .MuiButton-startIcon{
            margin-right:10px;
            margin-left:10px;
        }
    }
}