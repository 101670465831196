$root: ".NavBar";

#{$root} {
  &-logo {
    margin-left: -25px;
  }
}

@media only screen and (max-width: 600px) {
  #{$root} {
    &-logo {
      margin-left: 0;
    }
  }
}
