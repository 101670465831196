.date-story-card {
    padding: 24px 24px 24px 26px;
    /* White */

    background-color: #000 !important;
    /* Cards */
    border: 1px solid #E6E6E6;
    // box-shadow: 0px 0px 10px rgba(207, 206, 206, 0.8) !important;
    border-radius: 16px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    button {
        &:hover {
            background: #ff7c8956 !important;
        }
    }
}